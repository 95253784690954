import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from '../store/index'
const login = () => import('@/views/login/Login')

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    component: () => import('@/views/Index'),
    redirect: '/index',
    meta: {
      keepAlive: false,
      needLogin: false

    },
    children: [
      {
        path: '/index',
        name: 'userHome',
        component: () => import('@/views/UserHome'),
        meta: {
          keepAlive: false,
          needLogin: false,
          needPhone: false,
          title: '首页',
          role: 1

        }
      },

      {
        path: '/user/my',
        name: 'userMy',
        component: () => import('@/views/UserPersonalCenter'),
        meta: {
          keepAlive: false,
          needLogin: false,
          title: '个人中心',
          needPhone: false,
          role: 1

        }
      }
    ]
  },
  {
    path: '/business',
    component: () => import('@/views/Index'),
    redirect: '/business/home',
    meta: {
      keepAlive: false,
      needLogin: false
    },
    children: [
      {
        path: '/business/home',
        name: 'businessHome',
        component: () => import('@/views/coupon/BusinessHome'),
        meta: {
          keepAlive: false,
          needLogin: false,
          needPhone: false,
          title: '首页',
          role: 2

        }
      },

      {
        path: '/business/my',
        name: 'businessMy',
        component: () => import('@/views/coupon/BusinessPersonalCenter'),
        meta: {
          keepAlive: false,
          needLogin: true,
          needPhone: false,
          title: '个人中心',
          role: 2

        }
      }
    ]
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/setting/Setting'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: false,
      title: '设置',
      role: 1

    }
  },
  {
    path: '/monthlyRental/index',
    name: 'monthlyRentalIndex',
    component: () => import('@/views/vehicle-owner/monthly-rental/Index'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '月租办理',
      role: 1

    }
  },
  {
    path: '/monthlyRental/monthlyRentLong',
    name: 'monthlyRentLong',
    component: () => import('@/views/vehicle-owner/monthly-rental/MonthlyRentLong'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '办理长租',
      role: 1

    }
  },
  {
    path: '/monthlyRental/serviceChange',
    name: 'serviceChange',
    component: () => import('@/views/vehicle-owner/monthly-rental/ServiceChange'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '服务变更',
      role: 1

    }
  },
  {
    path: '/monthlyRental/serviceChangeRenew',
    name: 'serviceChangeRenew',
    component: () => import('@/views/vehicle-owner/monthly-rental/ServiceChangeRenew'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '服务变更续费',
      role: 1

    }
  },
  {
    path: '/monthlyRental/myService',
    name: 'myService',
    component: () => import('@/views/vehicle-owner/monthly-rental/myService'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '我的服务',
      role: 1

    }
  },
  {
    path: '/reletPostpone',
    name: 'reletPostpone',
    component: () => import('@/views/vehicle-owner/relet-postpone/ReletPostpone'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '续租延期',
      role: 1
    }
  },
  {
    path: '/postponeDetail',
    name: 'postponeDetail',
    component: () => import('@/views/vehicle-owner/relet-postpone/postponeDetail'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '续租延期详情',
      role: 1
    }
  },
  {
    path: '/myCoupon',
    name: 'myCoupon',
    component: () => import('@/views/vehicle-owner/my-coupon/MyCoupon'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '我的优惠券',
      role: 1
    }
  },
  {
    path: '/myCouponDetail/:type/:item/:tab',
    name: 'myCouponDetail',
    component: () => import('@/views/vehicle-owner/my-coupon/MyCouponDetail'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '优惠券详情',
      role: 1
    }
  },
  {
    path: '/myCar',
    name: 'myCar',
    component: () => import('@/views/vehicle-owner/my-car/MyCar'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '我的车辆',
      role: 1
    }
  },
  {
    path: '/myCarAdd',
    name: 'myCarAdd',
    component: () => import('@/views/vehicle-owner/my-car/MyCarAdd'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '添加车辆',
      role: 1
    }
  },
  {
    path: '/invoice/index',
    name: 'invoiceIndex',
    component: () => import('@/views/vehicle-owner/invoice/Index'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '发票服务',
      role: 1
    }
  },
  // {
  //   path: '/parkingInvoice',
  //   name: 'parkingInvoice',
  //   component: () => import('@/views/vehicle-owner/invoice/ParkingInvoice'),
  //   meta: {
  //     keepAlive: false,
  //     needLogin: true,
  //     needPhone: true,
  //     title: '停车开票',
  //     role: 1
  //   }
  // },
  {
    path: '/monthParkingInvoice',
    name: 'monthParkingInvoice',
    component: () => import('@/views/vehicle-owner/invoice/MonthParkingInvoice'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '月卡开票', // 默认月卡开票
      role: 1
    }
  },
  {
    path: '/invoiceRecord',
    name: 'invoiceRecord',
    component: () => import('@/views/vehicle-owner/invoice/InvoiceRecord'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '开票记录',
      role: 1
    }
  },
  {
    path: '/invoiceProblem',
    name: 'invoiceProblem',
    component: () => import('@/views/vehicle-owner/invoice/InvoiceProblem'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '常见问题',
      role: 1
    }
  },
  {
    path: '/invoiceMessage/:type/:num/:applyDirec',
    name: 'invoiceMessage',
    component: () => import('@/views/vehicle-owner/invoice/InvoiceMessage'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '发票信息',
      role: 1
    }
  },
  {
    path: '/parkingRecord',
    name: 'parkingRecord',
    component: () => import('@/views/vehicle-owner/parking-record/ParkingRecord'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '停车记录',
      role: 1
    }
  },
  {
    path: '/myBill',
    name: 'myBill',
    component: () => import('@/views/vehicle-owner/bill/MyBill'),
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '我的账单',
      role: 1
    }
  },
  {
    path: '/myBillDetail/:type/:item',
    name: 'myBillDetail',
    component: () => import('@/views/vehicle-owner/bill/MyBillDetail'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '账单详情',
      role: 1
    }
  },
  // 优惠券
  {
    path: '/giveRecord',
    name: 'giveRecord',
    component: () => import('@/views/coupon/record/GiveRecord'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '发放记录',
      role: 2
    }
  },
  {
    path: '/giveRecordDetail/:item',
    name: 'giveRecordDetail',
    component: () => import('@/views/coupon/record/GiveRecordDetail'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '发放记录详情',
      role: 2
    }
  },
  {
    path: '/payRecord',
    name: 'payRecord',
    component: () => import('@/views/coupon/record/PayRecord'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '购买记录',
      role: 2
    }
  },
  {
    path: '/giveCoupon',
    name: 'giveCoupon',
    component: () => import('@/views/coupon/operate-coupon/GiveCoupon'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '发放优惠券',
      role: 2
    }
  },
  {
    path: '/payCoupon',
    name: 'payCoupon',
    component: () => import('@/views/coupon/operate-coupon/PayCoupon'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: true,
      needPhone: true,
      title: '购买优惠券',
      role: 2
    }
  },
  {
    path: '/payMoney',
    name: 'payMoney',
    component: () => import('@/views/pay/PayMoney'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: false,
      needPhone: false,
      title: '订单支付',
      role: 1
    }
  },
  {
    path: '/payEscape',
    name: 'payEscape',
    component: () => import('@/views/pay/PayEscape'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: false,
      needPhone: false,
      title: '欠费订单',
      role: 1
    }
  },
  {
    path: '/advertising',
    name: 'payComplete',
    component: () => import('@/views/pay/PayComplete'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: false,
      needPhone: false,
      title: '支付完成',
      role: 1
    }
  },
  {
    path: '/code',
    // path: '/gainCoupon',
    // path: '/code/:sid/:rule_id:/:coupon_id/:type/:sTime/:eTime/:name/:category/:e_time_length/:allow_s_time/:allow_e_time/:value',
    // path: '/gainCoupon/:sid/:rule_id:/:coupon_id/:type/:sTime/:eTime/:name/:category/:e_time_length/:allow_s_time/:allow_e_time/:value',
    name: 'gainCoupon',
    component: () => import('@/views/pay/GainCoupon'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: false,
      needPhone: false,
      title: '领取优惠券',
      role: 1
    }
  },
  {
    path: '/codeObtain',
    name: 'codeObtain',
    component: () => import('@/views/components/CodeObtain'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: false,
      needPhone: false,
      title: '加载中',
      role: 1
    }
  },
  {
    path: '/vh_p_n_in',
    name: 'vh_p_n_in',
    component: () => import('@/views/vehicle-owner/visitor/vh_p_n_in'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: false,
      needPhone: false,
      title: '无牌车入场',
      role: 1
    }
  },
  {
    path: '/vh_visitor_in',
    name: 'vh_visitor_in',
    component: () => import('@/views/vehicle-owner/visitor/vh_visitor_in'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: false,
      needPhone: false,
      title: '访客车登记',
      role: 1
    }
  },
  {
    path: '/thr_coupon',
    name: 'thr_coupon',
    component: () => import('@/views/components/ThrCoupon'),
    props: true,
    meta: {
      keepAlive: false,
      needLogin: false,
      needPhone: false,
      title: '',
      role: 1
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  console.log('to', to)
  console.log('from', from)
  const mode = router.mode
  console.log('路由模式', mode)
  console.log('全部路由', router)
  const wantToPath = to.query.wantToPath // 判断wantToPage 的路由是否需要登录访问
  console.log('wantToPath', wantToPath)
  // 检查 wantToPath 是否需要登录访问
  if (wantToPath) {
    // TODO本地开发需要注释；正式发布时需要取消注释
    // next()
    // return

    // 存在则说明该菜单需要登录授权
    const resolvedRoute = router.resolve({ path: wantToPath }).route
    const needLoginForWantToPath = resolvedRoute.matched.some(record => record.meta.needLogin)
    console.log('wantToPath needLogin:', wantToPath, needLoginForWantToPath)
    if (needLoginForWantToPath) {
      // 对应路由需要登录
      const isLoggedIn = $store.state.accessToken.accessToken
      console.log('登录凭证isLoggedIn', isLoggedIn)
      const wxZfbAuthInfo = $store.state.wxZfbAuthInfo.wxZfbAuthInfo // 授权后获取的个人账号信息 头像 openid等
      if (!wxZfbAuthInfo) {
        console.log('from.fullPath', from.fullPath)
        // 存储当前URL
        sessionStorage.setItem('redirectTo', from.fullPath)
        next()
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
